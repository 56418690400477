import dayjs from 'dayjs'
import CONSTANTS from '@/config/constants'
import { loginInfo } from '@/services/auth'
import ENDPOINT from '@/config/endpoint'

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
}

export function convertDateTime(timestamp, format = 'ss:mm:HH DD/MM/YYYY') {
  return dayjs(timestamp * 1000).format(format)
}

export function numberWithDelimiter(number, delimiter = '.') {
  return Math.round(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, delimiter)
}

export function getTraslation(object, lang) {
  if (!object.translations) {
    return null
  }
  return (
    object.translations.find((t) => t.locale === lang) || object.translations[0]
  )
}

export function convertMenus(type) {
  const menu = CONSTANTS.MENU_ROUTERS.find((m) => m.type === type)
  if (menu) {
    return menu.title
  }
  return ''
}

export function getDataJs(data, page = 1, limit = 10, keyword = '', searchBy = 'name') {
  const offset = (page - 1) * limit
  let result = data
  if (keyword) {
    result = data.filter((d) => keyword.split(' ').every((v) => d[searchBy].toLowerCase().includes(v.toLowerCase())))
  }
  return {
    data: result.slice(offset).slice(0, limit),
    total: result.length
  }
}

export function getPlaceType(type) {
  const placeType = CONSTANTS.GROUP_PLACE_TYPES.find((m) => m.value === type)
  if (placeType) {
    return placeType.name
  }
  return ''
}

export function getCountry(id, countries) {
  const country = countries.find((m) => m.id === id)
  return country
}

export function getAccountOrg() {
  return loginInfo() && loginInfo().auth_user.organizations && loginInfo().auth_user.organizations[0] ? loginInfo().auth_user.organizations[0] : null
}

export function getAccountMenus() {
  const accountType = loginInfo() && loginInfo().auth_user ? loginInfo().auth_user.staff_type : ''
  if (accountType && accountType === 'moderator') {
    const org = getAccountOrg()
    const menus = org ? org.menus.split(',') : []
    return menus
  }
  return CONSTANTS.APP_MENUS ? CONSTANTS.APP_MENUS.split(',') : '*'
}

export function getAccountRouters() {
  const menus = getAccountMenus()
  if (menus === '*') {
    return CONSTANTS.MENU_ROUTERS
  }
  return menus.map((m) => {
    return CONSTANTS.MENU_ROUTERS.find((r) => r.type === m)
  }).sort((a, b) => a.stt - b.stt)
}

export function getPlaceTypes() {
  const menus = getAccountMenus()
  const types = CONSTANTS.GROUP_PLACE_TYPES.filter((p) => !p.hide)
  if (menus === '*') {
    return types
  }
  return types.filter((t) => menus.includes(t.value))
}

export function getOrgName(organization) {
  return organization ? organization.summary : 'Chưa có'
}

export function parseEndpointCategory(type) {
  switch (type) {
    case 'post':
      return ENDPOINT.POST_CATEGORIES
    case 'tour':
      return ENDPOINT.TOUR_CATEGORIES
    default:
      return ENDPOINT.PLACE_CATEGORIES
  }
}

export function orgContents(type, organization) {
  if (organization) {
    return organization[type] ? organization[type] || [] : []
  }
  return []
}

export function orgContentIds(type, organization) {
  return orgContents(type, organization).map((c) => c.id)
}

export function getDishType(type) {
  const dishType = CONSTANTS.DISH_TYPES.find((m) => m.value === type)
  if (dishType) {
    return dishType.label
  }
  return type
}

export function getDishSize(size) {
  const dishSize = CONSTANTS.DISH_SIZES.find((m) => m.value === size)
  if (dishSize) {
    return dishSize.label
  }
  return size
}

export function converData(row, colums) {
  const data = {}
  colums.forEach(colum => {
    switch (colum.key) {
      case 'object':
        data[colum.key] = row.object ? row.object.name : ''
        break
      case 'creator':
        data[colum.key] = row.creator ? row.creator.name : ''
        break
      case 'approved':
        data[colum.key] = row.approved ? 'Đã duyệt' : 'Chưa duyệt'
        break
      default:
        data[colum.key] = row[colum.key]
        break
    }
  })
  return data
}

export function parseExecutedStatus(status) {
  const cartStatus = CONSTANTS.MANAGE_CART_STATUS
  return cartStatus.find((s) => s.value === (status || 'new'))
}

export function parsePaymentStatus(status) {
  const cartStatus = CONSTANTS.MANAGE_PAYMENT_STATUS
  return cartStatus.find((s) => s.value === (status || 'open'))
}

export function pasreDishType(type) {
  const types = CONSTANTS.DISH_TYPES
  const typeValue = types.find((s) => s.value === type)
  return typeValue ? typeValue.label : 'Đồ ăn'
}

export function pasreDishSubcriptionStatus(dishSubcription) {
  const status = CONSTANTS.DISH_SUBCRIPTION_STATUS
  const statusValue = status.find((s) => s.value === dishSubcription)
  return statusValue
}

export function getToShowRouter(row) {
  const firstText = this.type.slice(0, 1).toUpperCase()
  const type = `${firstText}${this.type.slice(1, this.type.length)}`
  return { name: `${type}Info`, params: { id: row.id }}
}

export function getUserRoles() {
  const keys = CONSTANTS.USER_ROLE_KEYS
  let roles = CONSTANTS.USER_ROLES
  const staffType = loginInfo().auth_user.staff_type
  if (keys) {
    roles = CONSTANTS.USER_ROLES.filter((r) => keys.split(',').filter(Boolean).includes(r.value))
  }
  return roles.filter((r) => r.rules.includes(staffType))
}
