export default {
  HOTLINE: '0123456789',
  APP_TITLE: process.env.VUE_APP_TITLE,
  APP_LOGO_TITLE: process.env.VUE_APP_LOGO_TITLE,
  APP_MENUS: process.env.VUE_APP_MENUS,
  USER_ROLE_KEYS: process.env.VUE_APP_USER_ROLES,
  EDITOR_API_KEY: '1oo1lu0sbcnrznvh20hr6tatwezj2zflplh17eataa0p4kft',
  GOOGLE_API_KEY: 'ULQe0gEWUf1eXx7tGsTeBjd_TXzVsYIiake4y1OHKWE',
  MENU_ROUTERS: [
    { stt: 1, title: 'Trang chủ', name: 'Dashboard', type: 'dashboard', icon: 'fas fa-tachometer-alt', show: false },
    { stt: 2, title: 'Trang chủ', name: 'Country', type: 'country', icon: 'fas fa-globe', show: true },
    { stt: 3, title: 'Kiosk', name: 'Area', type: 'area', icon: 'fas fa-city', show: true, detail: true },
    { stt: 4, title: 'Tra cứu', name: 'Attraction', type: 'attraction', icon: 'fas fa-place-of-worship', show: true },
    { stt: 5, title: 'Bảo tàng', name: 'Museum', type: 'museum', icon: 'fas fa-mosque', show: true },
    { stt: 6, title: 'Sự kiện', name: 'Event', type: 'event', icon: 'fas fa-calendar-week', show: true },
    { stt: 7, title: 'Chỗ ở', name: 'Stay', type: 'stay', icon: 'fas fa-bed', show: true },
    { stt: 8, title: 'Ăn uống', name: 'Cuisine', type: 'cuisine', icon: 'fas fa-utensils', show: true },
    { stt: 9, title: 'Giải trí', name: 'Entertainment', type: 'entertainment', icon: 'fas fa-play-circle', show: true },
    { stt: 10, title: 'Doanh nghiệp', name: 'Company', type: 'company', icon: 'fas fa-building', show: true },
    { stt: 11, title: 'Chăm sóc sức khỏe', name: 'Health', type: 'health', icon: 'fas fa-heart', show: true },
    { stt: 12, title: 'Bất động sản', name: 'Real-estate', type: 'real-estate', icon: 'fas fa-landmark', show: true },
    { stt: 13, title: 'Cơ quan nhà nước', name: 'Government-agency', type: 'government-agency', icon: 'fas fa-building', show: true },
    { stt: 14, title: 'Tài chính', name: 'Financial', type: 'financial', icon: 'fas fa-dollar-sign', show: true },
    { stt: 15, title: 'Golf', name: 'Golf', type: 'golf', icon: 'fas fa-golf-ball', show: true },
    { stt: 16, title: 'Nhà hàng', name: 'Restaurant', type: 'restaurant', icon: 'fas fa-concierge-bell', show: true },
    { stt: 17, title: 'Dịch vụ khác', name: 'Other', type: 'other', icon: 'fas fa-align-justify', show: true },
    { stt: 18, title: 'Bài viết', name: 'Post', type: 'post', icon: 'fas fa-file', show: true },
    { stt: 19, title: 'Tour course', name: 'Tour', type: 'tour', icon: 'fab fa-foursquare', show: true },
    { stt: 20, title: 'Danh mục', name: 'Category', type: 'category', icon: 'fas fa-folder', show: false },
    { stt: 21, title: 'Campaign', name: 'Campaign', type: 'campaign', icon: 'fab fa-cuttlefish', show: true },
    { stt: 22, title: 'Bình luận', name: 'Comment', type: 'comment', icon: 'fas fa-comment', show: false },
    { stt: 23, title: 'Package', name: 'Package', type: 'package', icon: 'fas fa-folder', show: false },
    { stt: 24, title: 'Tổ chức', name: 'Organizations', type: 'organizations', icon: 'fas fa-sitemap', show: true },
    { stt: 25, title: 'Quản trị viên', name: 'Staff', type: 'staff', icon: 'fas fa-id-badge', show: false },
    { stt: 26, title: 'Người dùng', name: 'User', type: 'user', icon: 'fas fa-users', show: false }
  ],
  GROUP_PLACE_TYPES: [
    { name: 'Trang chủ', value: 'country', type: 'place', hide: true },
    { name: 'Kiosk', value: 'area', type: 'place', hide: true },
    { name: 'Tra cứu', value: 'attraction', type: 'place' },
    { name: 'Bảo tàng', value: 'museum', type: 'place' },
    { name: 'Sự kiện', value: 'event', type: 'place' },
    { name: 'Chỗ ở', value: 'stay', type: 'place' },
    { name: 'Ăn uống', value: 'cuisine', type: 'place' },
    { name: 'Giải trí', value: 'entertainment', type: 'place' },
    { name: 'Doanh nghiệp', value: 'company', type: 'place' },
    { name: 'Chăm sóc sức khỏe', value: 'health', type: 'place' },
    { name: 'Bất động sản', value: 'real-estate', type: 'place' },
    { name: 'Cơ quan nhà nước', value: 'government-agency', type: 'place' },
    { name: 'Tài chính', value: 'financial', type: 'place' },
    { name: 'Golf', value: 'golf', type: 'place' },
    { name: 'Nhà hàng', value: 'restaurant', type: 'place' },
    { name: 'Dịch vụ khác', value: 'other', type: 'place' },
    { name: 'Tour course', value: 'tour', type: 'tour' },
    { name: 'Bài viết', value: 'post', type: 'post' },
    { name: 'Campaign', value: 'campaign', type: 'campaign', hide: true }
  ],
  ALL_COLUMNS: [
    // Place table
    { value: 'stt', label: 'STT', key: 'stt', width: '80', align: 'left' },
    { value: 'avatar', label: '', key: 'avatar', width: '100', align: 'left' },
    { value: 'ordered', label: 'Thứ tự ưu tiên', key: 'ordered', width: '200', sortable: 'custom', align: 'center' },
    { value: 'name', label: 'Tên', key: 'name', width: '', align: 'left', sortable: 'custom' },
    { value: 'created_at', label: 'Ngày tạo', key: 'created_at', width: '120', align: 'center', sortable: 'custom' },
    { value: 'actions', label: 'Thao tác', key: 'actions', width: '200', align: 'center' },
    { value: 'language', label: 'Ngôn ngữ', key: 'language', width: '200', align: 'center' },

    // Comment table
    { value: 'c_object', label: 'Bài đăng', key: 'object', width: '200', align: 'left' },
    { value: 'c_creator', label: 'Tài khoản đăng', key: 'creator', width: '200', align: 'left' },
    { value: 'content', label: 'Nội dung', key: 'content', width: '', align: 'left', sortable: 'custom' },
    { value: 'c_approved', label: 'Trạng thái', key: 'approved', width: '100', align: 'left' },
    { value: 'c_created_at', label: 'Ngày đăng', key: 'created_at', width: '120', align: 'center', sortable: 'custom' },

    // Campaign table
    { value: 'cam_avatar', label: 'Banner', key: 'avatar', width: '100', align: 'left' },
    { value: 'cam_name', label: 'Tên campaign', key: 'name', width: '', align: 'left', sortable: 'custom' },
    { value: 'cam_times', label: 'Ngày áp dụng', key: 'times', width: '250', align: 'center' },

    // Area table
    { value: 'country', label: 'Đất nước', key: 'country', width: '250', align: 'left' }
  ],
  HEADER_ACTIONS: [
    { label: 'Thêm', key: 'add', icon: 'el-icon-circle-plus-outline', type: 'primary' },
    { label: 'Bộ lọc', key: 'filter', icon: 'el-icon-s-order', type: 'primary' },
    { label: 'Làm mới', key: 'reset', icon: 'el-icon-refresh-right', type: 'primary' },
    { label: 'Đồng bộ', key: 'synchronize', icon: 'el-icon-s-promotion', type: 'primary' },
    { label: 'Export', key: 'export', icon: 'el-icon-download', type: 'primary' },
    { label: 'Đơn hàng', key: 'cashier', icon: 'el-icon-shopping-cart-1', type: 'primary' },
    { label: 'Đầu bếp', key: 'cook', icon: 'el-icon-knife-fork', type: 'primary' },
    { label: 'Thống kê', key: 'statistic', icon: 'el-icon-s-data', type: 'primary' }
  ],
  ALL_ACTIONS: [
    { label: 'Xem mã QRCode', key: 'showQR', icon: 'fas fa-qrcode', type: 'info' },
    { label: 'Chi tiết', key: 'detail', icon: 'el-icon-document', type: 'primary' },
    { label: 'Chỉnh sửa', key: 'edit', icon: 'el-icon-edit', type: 'primary' },
    { label: 'Xóa', key: 'delete', icon: 'el-icon-delete', type: 'danger' },
    { label: 'Duyệt', key: 'browser', icon: 'el-icon-check', type: 'success' },
    { label: 'Bỏ duyệt', key: 'unbrowser', icon: 'el-icon-minus', type: 'info' },
    { label: 'Thêm vào danh sách con', key: 'add', icon: 'el-icon-plus', type: 'primary' },
    { label: 'Danh sách con', key: 'childrens', icon: 'el-icon-s-order', type: 'warning' },
    { label: 'Copy public link', key: 'copy', icon: 'el-icon-copy-document', type: 'info' }
  ],
  ALL_INFO_INPUTS: [
    {
      value: 'name',
      label: 'Tên',
      placeholder: 'Nhập tên, định danh',
      key: 'name',
      ref: 'form-name',
      type: 'input',
      required: true
    },
    {
      value: 'ordered',
      label: 'STT hiển thị',
      placeholder: 'Nhập số thứ tự hiển thị mong muốn',
      key: 'ordered',
      ref: 'form-ordered',
      type: 'number'
    },
    {
      value: 'qr_code',
      label: 'Qr code',
      placeholder: 'Nhập vào giá trị qr code',
      key: 'qr_code',
      ref: 'form-qr-code',
      type: 'input',
      required: true
    },
    {
      value: 'vrtour',
      label: 'Link Vrtour',
      placeholder: 'Nhập vào link vrtour',
      key: 'vrtour',
      type: 'input'
    },
    {
      value: 'vrtour_trailer',
      label: 'Link Trailer',
      placeholder: 'Nhập vào link trailer',
      key: 'vrtour_trailer',
      type: 'input'
    },
    {
      value: 'description',
      label: 'Mô tả',
      placeholder: 'Nhập vào mô tả ngắn gọn',
      key: 'description',
      type: 'textarea'
    },
    {
      value: 'content',
      label: 'Nội dung',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'content',
      type: 'editor'
    },
    {
      value: 'country_content',
      label: 'Giới thiệu',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'content',
      type: 'editor'
    },
    {
      value: 'local_cuisine',
      label: 'Ẩm thực địa phương',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'local_cuisine',
      type: 'editor'
    },
    {
      value: 'travel_experience',
      label: 'Kinh nghiệm du lịch',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'travel_experience',
      type: 'editor'
    },
    {
      value: 'vehicle_info',
      label: 'Phương tiện di chuyển',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'vehicle_info',
      type: 'editor'
    },
    {
      value: 'festival_info',
      label: 'Thông tin lễ hội',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'festival_info',
      type: 'editor'
    },
    {
      value: 'fun_facts',
      label: 'Những điều lý thú',
      placeholder: 'Nhập vào nội dung chi tiết',
      key: 'fun_facts',
      type: 'editor'
    },
    {
      value: 'navigation',
      label: 'Di chuyển',
      placeholder: 'Nhập vào thông tin',
      key: 'navigation',
      type: 'textarea'
    },
    {
      value: 'route_map',
      label: 'Route Map - Kinh nghiệm du lịch',
      placeholder: 'Nhập vào thông tin',
      key: 'route_map',
      type: 'textarea'
    },
    {
      value: 't_name',
      label: 'Tên Tour',
      placeholder: 'Nhập vào tên tour',
      key: 'name',
      type: 'input',
      ref: 'form-name',
      required: true
    },
    {
      value: 'price',
      label: 'Giá (VNĐ)',
      placeholder: 'Nhập vào giá tour',
      key: 'price',
      type: 'number',
      min: 0
    },
    {
      value: 'distance',
      label: 'Khoảng cách (km)',
      placeholder: 'Nhập vào khoảng cách',
      key: 'distance',
      type: 'number',
      min: 0
    },
    {
      value: 'agent_info',
      label: 'Thông tin đơn vị lữ hành',
      placeholder: 'Nhập thông tin',
      key: 'agent_info',
      type: 'textarea'
    },
    {
      value: 'policy',
      label: 'Chính sách Tour',
      placeholder: 'Nhập thông tin',
      key: 'policy',
      type: 'textarea'
    },
    {
      value: 'tip_hour',
      label: 'Tip & Hours',
      placeholder: 'Nhập thông tin',
      key: 'tip_hour',
      type: 'textarea'
    },
    {
      value: 'cam_name',
      label: 'Tên campaign',
      placeholder: 'Nhập vào tên campaign',
      key: 'name',
      type: 'input',
      ref: 'form-name',
      required: true
    },
    {
      value: 'cam_link',
      label: 'Link',
      placeholder: 'Nhập vào link',
      key: 'link',
      type: 'input'
    }
  ],
  DISH_TYPES: [
    { label: 'Món chính', value: 'main_dish' },
    { label: 'Đồ uống', value: 'beverage' },
    { label: 'Tráng miệng', value: 'dessert' }
  ],
  DISH_SIZES: [
    { label: 'Lớn', value: 'L' },
    { label: 'Trung bình', value: 'M' },
    { label: 'Nhỏ', value: 'S' }
  ],
  DISH_LABELS: [
    { label: 'Bán chạy nhất', value: 'favorites' },
    { label: 'Trẻ em', value: 'chilren' },
    { label: 'Món ăn chay', value: 'vegetarian' }
  ],
  MANAGE_PAGES: [
    { label: 'Quản lý đơn hàng F & B', key: 'cashier', name: 'ManageCashier', goback: 'Restaurant' },
    { label: 'Đầu bếp quản lý gọi món', key: 'cook', name: 'ManageCook', goback: 'Restaurant' },
    { label: 'Thống kê đơn hàng', key: 'statistic', name: 'ManageStatistic', goback: 'Restaurant' },
    { label: 'Quản lý tài khoản', key: 'account', name: 'ManageAccount', goback: '' }
  ],
  MANAGE_CART_STATUS: [
    { label: 'Tổng đơn hàng', key: 'all', value: '', type: 'primary' },
    { label: 'Đơn hàng mới', action: 'Làm mới', key: 'new', value: 'new', type: 'info' },
    { label: 'Đang xử lý', action: 'Đang xử lý', key: 'progressing', value: 'progressing', type: 'info' },
    { label: 'Chưa giao', action: 'Chưa giao', key: 'not_delivery', value: 'not_delivery', type: 'info' },
    { label: 'Đang giao', action: 'Giao hàng', key: 'is_delivering', value: 'is_delivering', type: 'info' },
    { label: 'Đã giao', action: 'Đã giao', key: 'delivered', value: 'delivered', type: 'info' },
    { label: 'Đã hủy', divided: true, action: 'Hủy đơn hàng', key: 'cancelled', value: 'cancelled', type: 'danger' },
    { label: 'Trả lại', divided: true, action: 'Trả lại', key: 'refund', value: 'refund', type: 'danger' },
    { label: 'Đã hoàn thành', divided: true, action: 'Hoàn thành', key: 'done', value: 'done', type: 'success' }
  ],
  MANAGE_PAYMENT_STATUS: [
    { label: 'Tất cả', key: 'all', value: '', type: 'warning' },
    { label: 'Chưa thanh toán', action: 'Thanh toán', action_value: 'paid', key: 'open', value: 'open', type: 'warning' },
    { label: 'Đã thanh toán', action: 'Hoàn tiền', key: 'paid', action_value: 'refund', value: 'paid', type: 'warning' },
    { label: 'Hoàn tiền', key: 'refund', value: 'refund', type: 'warning' }
  ],
  USER_ROLES: [
    { value: 'administrator', label: 'Quản lý', rules: ['administrator'] },
    { value: 'editor', label: 'Nhân viên', rules: ['administrator'] },
    { value: 'moderator', label: 'Nhân viên tổ chức', rules: ['administrator', 'editor'] },
    { value: 'cook', label: 'Đầu bếp', rules: ['administrator', 'editor', 'moderator'] },
    { value: 'cashier', label: 'Thu ngân', rules: ['administrator', 'editor', 'moderator'] },
    { value: 'user', label: 'Người dùng', rules: ['administrator', 'editor', 'moderator'] }
  ],
  DISH_SUBCRIPTION_STATUS: [
    { label: 'Chưa xử lý', action: 'Chưa xử lý', key: '', value: '', type: 'info' },
    { label: 'Đang xử lý', action: 'Đang xử lý', key: 'progressing', value: 'progressing', type: 'primary' },
    { label: 'Đã xử lý', action: 'Đã xử lý', key: 'completed', value: 'completed', type: 'success' }
  ]
}
